import Image from 'next/image';

import img404 from '@/assets/images/404.png';
import MainLayout from '@/components/Layout/Main';
import { CustomMeta } from '@/components/Meta/CustomMeta';
import Button from '@/components/Primitive/Button/Button';

const Page404 = () => {
  return (
    <MainLayout withNavSpacer>
      <CustomMeta title="404: Game Over" />
      <div className="pb-page container-md flex flex-col items-center justify-center overflow-x-hidden">
        <div className="space-y-4 pb-6 text-center">
          <h1 className="sr-only">404: Game Over</h1>
        </div>
        <div className="w-[120%] pb-8 sm:w-full">
          <Image
            src={img404}
            alt=""
            width={1200}
            height={571}
            sizes="(min-width: 768) 1200px, 100vw"
          />
        </div>
        <Button to="/" className="uppercase">
          Return to homepage
        </Button>
      </div>
    </MainLayout>
  );
};

export default Page404;
